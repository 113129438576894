/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Container,
  Form,
  Row
} from 'react-bootstrap';
import { useContext, useState } from 'react';
import { UserContext } from '../../UserProvider';
import { BUTTON_STYLES, FlowButton, LoadingIndicator } from '../../components/flowComponents';
import dayjs from 'dayjs'
import { groupBy } from '../../utils';
import { EventCard } from '../EventCard';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { useGetEventsForUser } from '../../fetch/endpoints';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import Icon, { TYPE_RIGHT_CARET_UNSET_FILL } from '../../daily/daily-components/Icon/Icon';
import { FC_DARK_BLUE } from '../../emotionVariables';
const sessionFilters = [
  "Hosting & Joining",
  "Hosting",
  "Joining",
] //as const
// type SessionFilter = typeof sessionFilters[number]

// We could also figure out `isPast from `listMode === USER_SESSION_LIST_MODES_PAST` but we'll just use this for now.
export const Events = ({ listMode, isPast = false }) => {
  const [paginationState, setPaginationState] = useState({
    startAtParticipantId: null,
    startAfterParticipantId: null,
    endBeforeParticipantId: null,
    prevStartAtParticipantIds: [],
  })
  const { startAtParticipantId, startAfterParticipantId, endBeforeParticipantId, prevStartAtParticipantIds } = paginationState

  const { result, fetching, performFetch } = useAutomaticallyFetch(useGetEventsForUser, { startAtParticipantId, startAfterParticipantId, endBeforeParticipantId, listMode }, { dependencies: [startAtParticipantId, startAfterParticipantId, endBeforeParticipantId] })
  const { events, morePages } = result ?? { events: null, morePages: false }
  const refetchEvents = () => performFetch({ startAtParticipantId, startAfterParticipantId, endBeforeParticipantId, listMode })

  const { user } = useContext(UserContext)

  const [filter, setFilter] = useState("Hosting & Joining")

  const loading = events === null || fetching

  const getFilteredEvents = events => events.filter(event => (filter === "Hosting" ? user.uid === event.hostId : filter === "Joining" ? user.uid !== event.hostId : true))

  const filteredEvents = getFilteredEvents(events ?? [])

  const sendSegmentEvent = useSendSegmentEvent()

  const pageEventsBackwards = () => {
    sendSegmentEvent(`${isPast ? 'Later' : 'Earlier'} Events Clicked`, { listMode, page: prevStartAtParticipantIds.length + 1 })
    const newStartAtParticipantId = prevStartAtParticipantIds.pop() ?? null
    setPaginationState({
      startAtParticipantId: newStartAtParticipantId,
      startAfterParticipantId: null,
      endBeforeParticipantId: events[0].participant.id,
      prevStartAtParticipantIds: [...prevStartAtParticipantIds],
    })
  }

  const pageEventsForwards = () => {
    sendSegmentEvent(`${isPast ? 'Earlier' : 'Later'} Events Clicked`, { listMode, page: prevStartAtParticipantIds.length + 1 })
    setPaginationState({
      startAtUserId: null,
      startAfterParticipantId: events[events.length - 1].participant.id,
      endBeforeParticipantId: null,
      prevStartAtParticipantIds: [...prevStartAtParticipantIds, events[0].participant.id],
    })
  }

  return (
    <Container>
      <Controls
        startAtParticipantId={startAtParticipantId}
        startAfterParticipantId={startAfterParticipantId}
        endBeforeParticipantId={endBeforeParticipantId}
        prevStartAtParticipantIds={prevStartAtParticipantIds}
        pageEventsBackwardsClicked={pageEventsBackwards}
        pageEventsForwardClicked={pageEventsForwards}
        morePages={morePages}
        filter={filter}
        setFilter={setFilter}
        isPast={isPast}
      />
      <EventsGroup events={filteredEvents} isPast={isPast} loading={loading} refetchEvents={refetchEvents} />
    </Container>
  )
}

const Controls = ({ startAtParticipantId, startAfterParticipantId, endBeforeParticipantId, prevStartAtParticipantIds,pageEventsBackwardsClicked, pageEventsForwardClicked, morePages, filter, setFilter, isPast }) => {
  return (
    <div css={css`display: flex; flex-direction: column; gap: 12px; padding: 0px 20px;`}>
      <div css={css`display: flex; justify-content: space-between;`}>
        <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={prevStartAtParticipantIds.length === 0} onClick={pageEventsBackwardsClicked}>
          <div css={css`display: flex; gap: 12px; align-items: center;`}>
            <Icon css={css`stroke: ${FC_DARK_BLUE}; transform: rotate(180deg); ${prevStartAtParticipantIds.length === 0 && css`stroke: #767676;`}`} type={TYPE_RIGHT_CARET_UNSET_FILL} />
            {`${isPast ? 'Later' : 'Earlier'}`}
          </div>
        </FlowButton>
        <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={!morePages && endBeforeParticipantId === null} onClick={pageEventsForwardClicked}>
          <div css={css`display: flex; gap: 12px; align-items: center;`}>
            {`${isPast ? 'Earlier' : 'Later'}`}
            <Icon css={css`stroke: ${FC_DARK_BLUE}; ${(!morePages && endBeforeParticipantId === null) && css`stroke: #767676;`}`} type={TYPE_RIGHT_CARET_UNSET_FILL} />
          </div>
        </FlowButton>
      </div>
      <div css={css`display: flex; justify-content: flex-end;`}>
        <Form.Control
          css={css`
            border: 1px solid rgba(0, 0, 0, 0.38);
            box-sizing: border-box;
            border-radius: 16px;
            width: 180px;
            height: 32px;
            color: rgba(0, 0, 0, 0.87);
            margin: 0px 8px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.25px;
          `}
          className="float-right fc-select"
          as="select"
          name="filter"
          value={filter}
          onChange={(e) => {
            //@ts-ignore:next-line
            setFilter(e.target.value)
          }}
        >
          {sessionFilters.map((filterValue) => (
            <option value={filterValue} key={filterValue}>{filterValue}</option>
          ))}
        </Form.Control>
      </div>
    </div>
  )
}

const EventsGroup = ({ events, isPast, loading, refetchEvents }) => {
  if (loading) {
    return <LoadingIndicator />
  }

  const eventsWithDate = events.map(event => ({ ...event, date: dayjs(event.start).format('YYYY-MM-DD') }))
  const eventsByDay = groupBy(eventsWithDate, 'date')

  return (
    <div>
      {Object.entries(eventsByDay).map(([date, dayEvents]) => (
        <div className="table-schedule" key={date}>
          <Row className="schedule-date d-flex justify-content-center">
            {dayjs(date).format('ddd M/D')}
          </Row>
          <div>
            {dayEvents.map((event, index) => <EventCard
              key={event.id}
              isFirst={index === 0}
              isLast={index === dayEvents.length - 1}
              event={event}
              sessionIsPast={isPast}
              onActionSuccess={refetchEvents}
              dontLinkCard={true}
              showSessionParticipant={true}
            />)}
          </div>
        </div>
      ))}
    </div>
  )
}