/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import Linkify from 'linkifyjs/react';

import EventContext from '../../../EventContext';
import { UserContext, usePreferences } from '../../../UserProvider';

import './Chat.scss';

import useSound from 'use-sound';
import MessageSound from './message.wav'
import { useChat } from './useChat';
import { CHAT_MESSAGE_COLORS, FC_BLUE, FC_DARK_BLUE, NEUTRAL_30, NEUTRAL_50, NEUTRAL_70, NEUTRAL_80, FC_GREEN, FC_LIGHT_BLUE } from '../../../emotionVariables';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import dayjs from 'dayjs';
import { FlowButton, LoadingIndicator, Modal, Text, TEXT_STYLES } from '../../../components/flowComponents';
import data from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'
import { flip, offset, shift } from '@floating-ui/react-dom';
import { Popover, usePopoverData } from '../../../components/Popover';
import { useCreateNewChatMessageReport, useRemoveChatMessageAttachment, useSendChatMessageAttachment } from '../../../fetch/endpoints';
import { groupObjectsByProperty } from '../../../../functions/shared/helpers';
import { TooltipWrapper } from '../../../components/TooltipWrapper';
import { DarkTooltip } from '../../../components/DarkTooltip';
import { CaretIcon } from './ChatIcons';
import Icon, { TYPE_FLAG } from '../Icon/Icon';
import { useModal } from '../../../wrappers/MagnificentlyMunificentModalManager';
import { toastError } from '../../../components/utils/toaster';
import { removeEmojis } from '../../../utils';

const linkifyOptions = {
  attributes: {rel: "noopener noreferrer"},
  target: {
    url: '_blank'
  },
}

export default function Chat({ active, activeWorkingTime }) {
  const sendSegmentEvent = useSendSegmentEvent()
  const { event: fcEvent, participants, sessionWorkingTimeStarted, sessionWorkingTimeFinished, setNewChatMessages, chatMessageAttachments } = useContext(EventContext);
  const { user } = useContext(UserContext)
  const isHost = fcEvent && user ? fcEvent.hostId === user.uid : false
  const [playMessageSound] = useSound(MessageSound)
  const chatFormRef = useRef(null)
  const inputRef = useRef(null)
  const [showNewMessages, setShowNewMessages] = useState(false)
  const [isAtBottom, setIsAtBottom] = useState(false)


  const scrollToBottom = () => {
    chatFormRef.current.scrollIntoView(false, { behavior: 'smooth', block: 'start'});
    setShowNewMessages(false)
    setNewChatMessages(false)
    setIsAtBottom(true)
  }

  const { chatHistory, sendMessage, messageSending } = useChat(user, fcEvent.id, participants, user.uid, isHost, sessionWorkingTimeStarted, sessionWorkingTimeFinished)
  
  useEffect(() => {
    if (chatHistory.length > 0) {
      // Make other icons light up
      if (!active) {
        setNewChatMessages(true);
        if (isHost && activeWorkingTime) {
          playMessageSound()
        }
      } else if (!isAtBottom) {
        setShowNewMessages(true)
      }
    }
  }, [chatHistory.length])

  useEffect(() => {
    const callback = (entries, _observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          scrollToBottom()
        } else {
          setIsAtBottom(false)
        }
      });
    };
    const observer = new IntersectionObserver(callback);
    if (chatFormRef.current) {
      observer.observe(chatFormRef.current);
    }

    return () => {
      if (chatFormRef.current) {
        observer.unobserve(chatFormRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (active) {
      scrollToBottom()
    }
  }, [active])

  function handleSubmit(event) {
    event.preventDefault();
    const inputValue = inputRef.current.value;
    sendSegmentEvent("Chat Message Sent", { message: inputValue })
    if (inputValue !== '' && !messageSending) {
      sendMessage(inputValue)
      inputRef.current.value = '';
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSubmit(event);
    }
  }

  const attachmentsByMessageId = groupObjectsByProperty(chatMessageAttachments, 'baseMessageId')

  return (
    <div css={css`
      height: 100%;
      background: #FFFFFF;
      font-size: 14px;
      color: #000000;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}>
      <div css={css`
        flex-grow: 1;
        overflow-y: auto;
      `}>
        {chatHistory.map((message) => (
          <ChatMessage
            key={`${message.id}${message.pending}`}
            message={message}
            attachments={attachmentsByMessageId[message.id] ?? []}
          />
        ))}
      </div>
      {showNewMessages && <div css={css`
        position: absolute;
        bottom: 10px;
        left: 100px;
        background: ${FC_GREEN};
        color: #fff;
        padding: 5px 10px;
        cursor: pointer;
        width: 150px;
        border-radius: 15px;
        z-index: 5px;
        text-align: center;
      `} onClick={scrollToBottom}>
        Newer messages
        <CaretIcon customCss={css`margin-left: 10px; width: 12px; height: 6px; fill: #ffffff;`} />
      </div>}
      <form onSubmit={handleSubmit} ref={chatFormRef}>
        <Form.Control
          css={css`
            height: 48px;
            background: rgba(196, 196, 196, 0.24);
            border-radius: 6px;
            resize: none;
            border: none;
            font-size: 14px;
            padding: 14px 12px 13px;
            margin-top: 16px;

            &::placeholder {
              color:rgba(0, 0, 0, 0.6);
            }
          `}
          id="chatInput"
          as="textarea"
          rows={2}
          name="chatMessage"
          ref={inputRef}
          onKeyDown={handleKeyPress}
          placeholder="Type your message here..."
          enterKeyHint="Send"
          autoCapitalize="none"
        />
      </form>
    </div>
  )
}

const ReportChatMessageModal = ({ message, sessionId, onClose = () => {} }) => {
  const { user } = useContext(UserContext)
  const [ reason, setReason ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)
  const [ submitting, setSubmitting ] = useState(false)
  const { senderId, id: chatMessageId } = message
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: reportChatMessage } = useCreateNewChatMessageReport()

  const handleChange = (event) => {
    setReason(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSubmitting(true)
    const { success, error } = await reportChatMessage({
      senderId,
      sessionId,
      chatMessageId,
      reason,
    })
    setSubmitting(false)
    sendSegmentEvent("Chat Message Flag Submitted", { from: "inSessionChat", messageId: chatMessageId, senderId, sessionId, reportingUserId: user.uid })
    if (success) {
      setSubmitted(true)
    } else {
      console.error(error)
      toastError({ message: "Error submitting chat message report. Please try again." })
    }
  }

  return (
    <Modal width={500} onClose={onClose}>
      <div css={css`
        padding: 32px 24px 12px 24px;
      `}>
        <Text style={TEXT_STYLES.APP_H5} customCss={css`margin-bottom: 24px;`}>
          Report Chat Message
        </Text>
        {submitted ?
          <div>
            <Text style={TEXT_STYLES.BODY}>Thank you for reporting this chat message. Our team will review it shortly.</Text>
            <FlowButton customCss={css`margin-top: 24px;`} onClick={onClose}>Close</FlowButton>
          </div>
        :
        <div>
        <Text style={TEXT_STYLES.BODY}>Please provide a reason why this chat message violates the <a href="https://www.flow.club/community-guidelines" target="_blank" rel="noopener noreferrer">community guidelines.</a></Text>
        <div css={css`padding: 8px; background: ${FC_LIGHT_BLUE}; border-radius: 6px; margin-top: 16px;`}>
          <Text style={TEXT_STYLES.CAPTION}>{message.sender}: {message.message}</Text>
        </div>
        {submitting ? <Text style={TEXT_STYLES.BODY} customCss={css`margin-top: 16px;`}><LoadingIndicator /> Submitting...</Text> :
        <form onSubmit={handleSubmit}>
        <Form.Control
          css={css`
            height: 48px;
            background: rgba(196, 196, 196, 0.24);
            border-radius: 6px;
            resize: none;
            border: none;
            font-size: 14px;
            padding: 14px 12px 13px;
            margin-top: 16px;

            &::placeholder {
              color:rgba(0, 0, 0, 0.6);
            }
          `}
          id="chatInput"
          as="textarea"
          rows={2}
          name="reason"
          value={reason}
          onChange={handleChange}
          placeholder="Provide your reason here..."
        />
        <FlowButton type="submit" customCss={css`margin-top: 24px;`}>Submit</FlowButton>
        </form>}
        </div>}
      </div>
    </Modal>
  )
}

const popoverPlacement = { placement: 'bottom', middleware: [shift(), flip()] }

const ChatMessage = ({ message, attachments }) => {
  const { user } = useContext(UserContext)
  const { hideDisplayNameEmojis } = usePreferences()
  const { activeUserParticipant, event, participants } = useContext(EventContext)
  const chatHistoryPastMessagesCutoff = activeUserParticipant.joined !== undefined ? activeUserParticipant.joined.toMillis() : Date.now()
  const [emojiReacting, setEmojiReacting] = useState(false)
  const { performFetch: sendEmojiReaction } = useSendChatMessageAttachment()
  const { performFetch: removeEmojiReaction } = useRemoveChatMessageAttachment()
  const sendSegmentEvent = useSendSegmentEvent()
  const { setActiveModal } = useModal()
  const chatMessagePopoverData = usePopoverData(popoverPlacement)

  const attachmentsByEmojiValue = groupObjectsByProperty(attachments, 'value')
  const groupedEmojis = Object.keys(attachmentsByEmojiValue).map(emojiValue => ({
    value: emojiValue,
    count: attachmentsByEmojiValue[emojiValue].length,
    viewingUserAttachment: attachmentsByEmojiValue[emojiValue].find(attachment => attachment.sender === user.uid),
    reactedBy: attachmentsByEmojiValue[emojiValue].map(attachment => participants[attachment.sender] !== undefined ? (hideDisplayNameEmojis ? removeEmojis(participants[attachment.sender].name) : participants[attachment.sender].name) : "Unknown").join(", ")
  }))

  const addEmojiButtonClicked = (e) => {
    sendSegmentEvent("Emoji Selector Opened", { from: "inSessionChat" })
    setEmojiReacting(!emojiReacting)
  }

  const flagMessage = () => {
    sendSegmentEvent("Chat Message Flag Modal Opened", { from: "inSessionChat", messageId: message.id, senderId: message.senderId, sessionId: event.id, reportingUserId: user.uid })
    setActiveModal(ReportChatMessageModal, {
      message,
      sessionId: event.id,
      onClose: () => setActiveModal(null)
    })
  }

  const emojiSelected = (emoji) => {
    const { native } = emoji
    const alreadyAddedEmoji = groupedEmojis.find(({ value }) => value === native)?.viewingUserAttachment
    if (alreadyAddedEmoji === undefined) {
      sendEmojiReaction({ eventId: event.id, attachment: { value: native, type: 'emoji' }, messageId: message.id })
      sendSegmentEvent("Emoji Reaction Added", { from: "inSessionChat", repeatingExistingReaction: false, emoji: native })
    } else {
      removeEmojiReaction({ attachmentId: alreadyAddedEmoji.id })
      sendSegmentEvent("Emoji Reaction Removed", { from: "inSessionChat", emoji: native })
    }
    setEmojiReacting(false)
  }

  const { senderId, sender, senderColorIndex, message: messageText, pending, sentAt } = message
  const senderDisplayName = sender && hideDisplayNameEmojis ? removeEmojis(sender) : sender

  const botMessage = sender === undefined
  const pastMessage = chatHistoryPastMessagesCutoff > sentAt

  const existingAttachmentClicked = (viewingUserAttachment, emojiValue) => {
    if (viewingUserAttachment === undefined) {
      sendEmojiReaction({ eventId: event.id, attachment: { value: emojiValue, type: 'emoji' }, messageId: message.id })
      sendSegmentEvent("Emoji Reaction Added", { from: "inSessionChat", repeatingExistingReaction: true, emoji: emojiValue })
    } else {
      removeEmojiReaction({ attachmentId: viewingUserAttachment.id })
      sendSegmentEvent("Emoji Reaction Removed", { from: "inSessionChat", emoji: emojiValue })
    }
  }
  
  return (
    <div css={css`
      margin-bottom: 8px;
      word-break: normal;
      hyphens: auto;

      .timestamp {
        transition: width 0.2s ease-out;
        width: 0px;
        overflow: hidden;
      }
      .add-emoji-button {
        visibility: hidden;
      }
      .flag-message-button {
        visibility: hidden;
      }
      &:hover {
        .timestamp { width: 38px; }
        .add-emoji-button { visibility: visible; }
        .flag-message-button { visibility: visible; }
      }
    `}>
      <div css={css`position: relative;`}>
        <div css={css`display: flex; flex-wrap: wrap;`}>
          <div className={'timestamp'}>
            <Text style={TEXT_STYLES.CAPTION} customCss={css`display: inline; margin-right: 4px; color: ${NEUTRAL_70};`}>{dayjs(sentAt).format("HH:mm")}</Text>
          </div>
          {!botMessage && <span css={css`margin-right: 4px;`}><span style={{ color: CHAT_MESSAGE_COLORS[senderColorIndex], fontWeight: "bold", opacity: (pending || (!botMessage && pastMessage)) ? 0.5 : 1}}>{senderDisplayName}</span>: </span>}
          <span style={botMessage ? { color: "rgba(0,0,0,0.6)", fontStyle: "italic" } : {opacity: (pending || (!botMessage && pastMessage)) ? 0.5 : 1}}><Linkify tagName="span" options={linkifyOptions}>{messageText}</Linkify></span>
          {emojiReacting && <Popover floatingUIData={chatMessagePopoverData} animate={false} closePopup={() => setEmojiReacting(false)}>
            <EmojiPicker perLine={7} data={data} onEmojiSelect={emojiSelected} />
          </Popover>}
        </div>
        {!botMessage && 
          <div css={css`display: flex; gap: 8px;`}>
            {groupedEmojis.map(({ value, count, viewingUserAttachment, reactedBy }) =>
              <TooltipWrapper retainMousedOverInsideTooltip={false} TooltipComponent={DarkTooltip} useFloatingArgs={{ placement: 'bottom', middleware: [offset(12), flip()] }} TooltipContents={reactedBy}>
                <div onClick={() => existingAttachmentClicked(viewingUserAttachment, value)} key={value}
                  css={css`
                    width: 48px;
                    height: 24px;
                    padding: 0px 8px;
                    border-radius: 12px;
                    background-color: ${viewingUserAttachment === undefined ? NEUTRAL_30 : FC_BLUE};
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    font-size: 16px;
                    cursor: pointer;
                    user-select: none;
                    transition: background-color 0.15s;
                    ${viewingUserAttachment === undefined && css`
                      &:hover, &:active {
                        background-color: ${NEUTRAL_50};
                        transition: none;
                      }
                    `}
                  `}
                >
                  <Text customCss={viewingUserAttachment !== undefined && css`color: white;`}>{value}</Text>
                  <Text customCss={viewingUserAttachment !== undefined && css`color: white;`}>{count}</Text>
                </div>
              </TooltipWrapper>
            )}
            <div css={css`
              display: flex;
              flex-direction: row;
              gap: 8px;
              ${groupedEmojis.length === 0 && css`position: absolute; right: 0px; bottom: 0px; z-index: 1;`}
              `}>
              <div className='add-emoji-button' ref={chatMessagePopoverData.reference}>
                <div onClick={addEmojiButtonClicked} css={css`width: 48px; height: 24px; border-radius: 12px; background-color: ${NEUTRAL_80}; border: 1px solid transparent; display: flex; justify-content: center; align-items: center; color: ${NEUTRAL_50}; font-size: 18px; cursor: pointer; user-select: none; &:hover, &:active { border: 1px solid ${FC_DARK_BLUE}; color: white; }`}>+</div>
              </div>
              {senderId !== user.uid && <FlagMessageButton onClick={flagMessage} />}
          </div>
          </div>
        }
      </div>
    </div>
  )
}

const FlagMessageButton = ({ onClick }) => {
  const parentStyle = css`
    width: 48px;
    height: 24px;
    border-radius: 12px;
    background-color: ${NEUTRAL_80};
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${NEUTRAL_50};
    font-size: 18px;
    cursor: pointer;
    user-select: none;

    --icon-fill-color: ${NEUTRAL_50}; /* Default fill color */
    
    &:hover, &:active {
      border: 1px solid ${FC_DARK_BLUE};
      --icon-fill-color: white; /* Change fill color on active/hover */
    }
  `;
  const iconStyle = css`
    width: 18px;
    height: 18px;
    fill: var(--icon-fill-color); /* Use CSS variable */
  `;

  return (
    <div className='flag-message-button'>
      <div onClick={onClick} css={parentStyle}>
        <Icon type={TYPE_FLAG} css={iconStyle} />
      </div>
    </div>
  )
  
}